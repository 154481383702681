(function () {
  "use strict";

  function initNav() {
    const navigation = new Navigation(document.getElementById("navigation"), {
      breakpoint: 1140
    });
  }

  function heroSlider() {
    if (document.querySelector('.hero-slider')) {
      const swiper = new Swiper('.hero-slider .swiper', {
        loop: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // },
        navigation: {
          nextEl: '.hero-slider .slider-button-next',
          prevEl: '.hero-slider .slider-button-prev',
        },
      })
    }
  }

  // Uluslararası Telefon Input
  function telefonInput() {
    // Slider Altı Form
    if (document.querySelector('#telInput')) {
      var input = document.querySelector("#telInput");
      window.intlTelInput(input, {
        utilsScript: "/assets/js/global/website/utils.js"
      });
    }

  }

  // Video Slider
  function videoSlider() {
    if (document.querySelector('.video-slider')) {
      const slider = new Swiper('.video-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".video-slider .slider-button-default-next",
          prevEl: ".video-slider .slider-button-default-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }


  // Hizmet Slider
  function hizmetNoktalariSlider() {
    if (document.querySelector('.hizmet-noktalari-slider')) {
      const hizmetNoktalariSlider = new Swiper('.hizmet-noktalari-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".hizmet-noktalari-slider .slider-button-default-next",
          prevEl: ".hizmet-noktalari-slider .slider-button-default-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          // 992: {
          //   slidesPerView: 3,
          // },
        }
      })
    }

  }


  // Scroll top Button

  // function scrollTop() {
  //   const btnScrollTop = document.getElementById('scrollTop')
  //   document.addEventListener('scroll', () => {
  //     if (window.scrollY > 1000) {
  //       btnScrollTop.classList.remove('hide-btn');
  //     } else {
  //       btnScrollTop.classList.add('hide-btn');
  //     }
  //   })

  //   btnScrollTop.addEventListener('click', () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth"
  //     });
  //   })
  // }


  initNav()

  heroSlider()
  telefonInput()
  videoSlider()
  hizmetNoktalariSlider()
  //scrollTop()

})();